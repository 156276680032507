import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from './../../environments/environment';
import {map} from 'rxjs/operators';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/retry';

// import { AngularFirestore } from '@angular/fire/firestore';
import moment from 'moment';
import {AppState} from './../app.state';
import {Store, select} from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import {SubjectService} from './subject.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  url: string = '';
  httpHeaders;
  options: any;
  apiurl = environment.api_endpoint;
  mode = environment.mode;
  sapHeaders: any;
  constructor(private http: HttpClient) //private store: Store<AppState>,
  //private subject:SubjectService
  //private firestore: AngularFirestore,
  {
    this.httpHeaders = new HttpHeaders();
    this.httpHeaders.set('Content-Type', 'application/json');
    this.options = {
      headers: this.httpHeaders,
    };

    this.sapHeaders = {
      headers: {'Content-Type': 'application/json'},
    };
  }

  public isAuthenticated(): boolean {
    let isAuth: boolean = false;
    let token = sessionStorage.getItem('token') || '';
    // this.store.pipe(select('user')).subscribe((currentUserInfo: any) => {
    //   console.log(currentUserInfo);
    //   if (currentUserInfo[0] && currentUserInfo[0].token) {
    //    let token = currentUserInfo[0].token;
    if (token) {
      let decode: any = jwt_decode(token);
      const expDate = new Date(0).setUTCSeconds(decode.exp);
      if (expDate.valueOf() > new Date().valueOf()) {
        // this.subject.setCurrentUserInfo(currentUserInfo[0]);
        //this.httpHeaders.set("Authorization", `Bearer${token}`);
        isAuth = true;
      } else {
        isAuth = false;
      }
    } else {
      isAuth = false;
    }

    // } else {
    //   this.subject.getCurrentUserInfo().subscribe((currentUser)=>{
    //     console.log(currentUser);
    //   })
    //   isAuth = false;
    // }
    //});
    return isAuth;
  }

  getCurrentUserInfo() {
    // this.store.pipe(select('user')).subscribe((currentUserInfo: any) => {
    //   return currentUserInfo;
    //   // if(currentUserInfo[0]) {
    //   //   //delete currentUserInfo[0].token;
    //   //   return {status:"success",result:currentUserInfo[0]};
    //   // } else {
    //   //   return {status:"failure"}
    //   // }
    // });
  }

  /* User - Check Login credentials api call*/
  login(data: any) {
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/user/login';
    }
    return this.http.post(this.url, data, this.options);
  }

  /* User - Check Userid exists or not - api call*/
  checkUserid(data: any) {
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/user/checkUserid';
    }
    return this.http.post(this.url, data, this.options);
  }

  createUser(data: any) {
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/user/create';
    }
    return this.http.post(this.url, data, this.options);
  }

  /* User - Update user info - api call */
  updateUserInfo(data: any) {
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/user/updateUserInfo';
    }
    return this.http.post(this.url, data, this.options);
  }

  getUsers() {
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/user/get';
    }
    return this.http.get(this.url, this.options);
  }

  deleteUser(id) {
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/user/delete';
    }
    return this.http.post(this.url, {id: id}, this.options);
  }

  /* Threshold Tag - Insert new threshold tags api call */
  insertThreshold(data: any) {
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/threshold/insertThreshold';
    }
    return this.http.post(this.url, data, this.options);
  }

  /* Threshold Tag - Update existing threshold tags api call */
  updateThreshold(data: any) {
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/threshold/updateThreshold';
    }
    return this.http.post(this.url, data, this.options);
  }

  /* ChartConfig - Insert new chart config api call */
  getWidgets(data: string) {
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/chartconfig/getWidgets/?asset_code=' + data;
    }
    return this.http.get(this.url, this.options);
  }

  /* ChartConfig - Insert new chart config api call */
  insertWidget(data: any) {
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/chartconfig/insertWidget';
    }
    return this.http.post(this.url, data, this.options);
  }

  /* Get Whole Asset Data */
  getAssetData() {
    // this.url = "assets/json/assetData.json";
    // return this.http.get(this.url, this.options);
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/chartconfig/getAssets';
    }
    return this.http.get(this.url, this.options);
  }

  /* Get Alert Data */
  getAlertData(data: any): Observable<any> {
    // this.url = "assets/json/alertData.json";
    // let data:any = this.http.get(this.url, this.options);
    // return data;
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/alert/get';
    }
    return this.http.post(this.url, data, this.options);
  }

  updateAlert(data: any) {
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/alert/update';
    }
    return this.http.post(this.url, data, this.options);
  }

  getThresholdData(assetcode: string): Observable<any> {
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/threshold/get?assetcode=' + assetcode;
    }
    return this.http.get(this.url, this.options);
  }

  saveWidgetOrder(data) {
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/chartconfig/saveorder';
    }
    return this.http.post(this.url, data, this.options);
  }

  updateCoordinates(data) {
    if (this.mode == 'edge') {
      this.url = this.apiurl + 'api/chartconfig/updateOrdinates';
    }
    return this.http.post(this.url, data, this.options);
  }

  /*********************************************************** */
  getHistoryData(json?: any) {
    // return this.firestore.collection(`/Asset_OEE/SONA/${json.collection}/${json.asset}/${json.assetcode}`,
    //   ref =>
    //     ref
    //       .where("timestamp", ">=", moment(json.startDate).toDate()).where("timestamp", "<=", moment(json.endDate).toDate())
    // ).get()
    return this.http.post(
      `${this.apiurl}api/history/get`,
      json,
      this.httpHeaders,
    );
  }

  getindicatordata(json: any) {
    // return this.firestore.collection("/Asset_OEE/SONA/HealthData", ref => ref.where("assetcode", "==", json.assetcode)).get()
  }
  /*********************************************************** */

  savetoSAP(params: any) {
    let url = '';
    let basicAuth: any;
    if (params.department == 'sms') {
      url = environment.ProdSAPSpecro;
      basicAuth = 'JSPLPI23:abcd1234';
    } else {
      if (params.processType == 'in_process') {
        url = environment.PRODXRFIP;
      } else if (params.processType == 'post_manufacturing') {
        url = environment.PRODXRFPM;
      } else {
        url = environment.PRODSRFRM;
      }
      basicAuth = 'jsplpi15:abcd1234';
    }
    // console.log(params.data, url,basicAuth)
    // url=url.replace('vipcipo.jspl.com','34.100.177.153')
    return this.http.post(url, params.data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(basicAuth),
      }),
    });
  }

  // saveElementOrder(data) {
  //   if (this.mode == "edge") {
  //     this.url = this.apiurl + "api/chartconfig/saveorder";
  //   }
  //   return this.http.post(this.url, data, this.options);
  // }
  customQuery(params) {
    let apiDetails = params.apiDetails;
    if (params.datesRequired) {
      apiDetails.reqParams['startDate'] = moment()
        .subtract(apiDetails.reqParams['startDate'], 'days')
        .toDate();
      apiDetails.reqParams['endDate'] = moment()
        .subtract(apiDetails.reqParams['endDate'], 'days')
        .toDate();
    }
    console.log(apiDetails.reqParams);
    let url =  'https://asia-south1-jspl-355706.cloudfunctions.net/GenAI-Report-Generation/GenerateReport?startDate=10-08-2023&endDate=15-08-2023&type=production&report_type=text/'

    return this.http.get(url, {headers:{
      'Content-Type': 'application/json'
    }})
  }
}
